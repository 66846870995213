import React, {useEffect, useState} from 'react';
import ingreso from '../../assets/images/delivery.svg';
import curso from '../../assets/images/van.svg';
import entregado from '../../assets/images/correct.svg';
import './style.scss';
import {STATUS_TRANSACTIONS} from "../../utils/const";
import {format} from "date-fns";

const Step = ({ history = []} = {}) => {

    const errorsEntered = [STATUS_TRANSACTIONS.ERROR_VALIDATION, STATUS_TRANSACTIONS.CANCELED, STATUS_TRANSACTIONS.ERROR]
    const okEntered = [STATUS_TRANSACTIONS.CREATED, STATUS_TRANSACTIONS.CONFIRMED, STATUS_TRANSACTIONS.READY]

    const [colorClassStep, setColorClassStep] = useState({one: 'bg-gray', two: 'bg-gray', three: 'bg-gray'})
    const [colorClassImage, setColorClassImage] = useState({
        one: 'filter-gray',
        two: 'filter-gray',
        three: 'filter-gray'
    })
    const [colorClassDashed, setColorClassDashed] = useState({one: 'on-hold', two: 'on-hold'})
    const [dateStepOne, setDateStepOne] = useState('')
    const [dateStepTwo, setDateStepTwo] = useState('')
    const [dateStepThree, setDateStepThree] = useState('')


    useEffect(() => {
        if (history.length) {
            changeVisualStyles()
        }
    }, [history]);


    const changeVisualStyles = () => {
        const delivered = history.findIndex(state => state.status === STATUS_TRANSACTIONS.DELIVERED)
        const inTransit = history.findIndex(state => state.status === STATUS_TRANSACTIONS.IN_TRANSIT)
        const entered = history.findIndex(state => okEntered.includes(state.status))
        const returned = history.findIndex(state => state.status === STATUS_TRANSACTIONS.RETURNED)
        const error = history.findIndex(state => state.status === STATUS_TRANSACTIONS.ERROR)
        const errorEntered = history.findIndex(state => errorsEntered.includes(state.status))

        if (returned !== -1) {
            setColorClassImage({one: '', two: '', three: 'filter-gray'})
            setColorClassStep({one: 'bg-primary', two: 'bg-primary', three: 'bg-danger'})
            setColorClassDashed({one: 'success', two: 'success'})
            setDateStepThree(format(new Date(history[returned].date), 'dd/MM/yyyy - H:mm'))
            setDateStepTwo(format(new Date(history[inTransit].date), 'dd/MM/yyyy - H:mm'))
            setDateStepOne(format(new Date(history[entered].date), 'dd/MM/yyyy - H:mm'))
        } else if (delivered !== -1) {
            setColorClassImage({one: '', two: '', three: ''})
            setColorClassStep({one: 'bg-primary', two: 'bg-primary', three: 'bg-primary'})
            setColorClassDashed({one: 'success', two: 'success'})
            setDateStepThree(format(new Date(history[delivered].date), 'dd/MM/yyyy - H:mm'))
            setDateStepTwo(format(new Date(history[inTransit].date), 'dd/MM/yyyy - H:mm'))
            setDateStepOne(format(new Date(history[entered].date), 'dd/MM/yyyy - H:mm'))
        } else if (inTransit !== -1 && error === -1) {
            setColorClassImage({one: '', two: '', three: 'filter-gray'})
            setColorClassStep({one: 'bg-primary', two: 'bg-primary', three: 'bg-gray'})
            setColorClassDashed({one: 'success', two: 'success'})
            setDateStepTwo(format(new Date(history[inTransit].date), 'dd/MM/yyyy - H:mm'))
            setDateStepOne(format(new Date(history[entered].date), 'dd/MM/yyyy - H:mm'))
        } else if (errorEntered !== -1) {
            setColorClassImage({one: 'filter-gray', two: 'filter-gray', three: 'filter-gray'})
            setColorClassStep({one: 'bg-danger', two: 'bg-gray', three: 'bg-gray'})
            setColorClassDashed({one: 'error', two: 'on-hold'})
            setDateStepOne(format(new Date(history[errorEntered].date), 'dd/MM/yyyy - H:mm'))
        } else if (entered !== -1) {
            setColorClassImage({one: '', two: 'filter-gray', three: 'filter-gray'})
            setColorClassStep({one: 'bg-primary', two: 'bg-gray', three: 'bg-gray'})
            setColorClassDashed({one: 'success', two: 'on-hold'})
            setDateStepOne(format(new Date(history[entered].date), 'dd/MM/yyyy - H:mm'))
        }
    }


    return (
        <div className="row stepper d-flex flex-column flex-md-row">
            <div className={`col d-flex align-items-center flex-md-column-reverse my-3 dashed ${colorClassDashed.one}`}>
                <div className="text-center mx-auto d-flex flex-column justify-content-center">
                    <img width="96" src={ingreso} alt=""
                         className={`${colorClassImage.one} mx-auto`}/>
                    <div
                        className="stepper__circle col-3 col-md-12 text-center d-flex flex-column align-items-center my-3">
                        <span
                            className={`${colorClassStep.one} rounded-circle py-2 px-3 text-white`}>1</span>
                    </div>
                    <p className="fw-bold mb-0">Pedido ingresado</p>
                    {
                        dateStepOne ? <small>{dateStepOne}</small> : <small>&nbsp;</small>
                    }
                </div>
            </div>
            <div className={`col d-flex align-items-center flex-md-column-reverse my-3 dashed ${colorClassDashed.two}`}>
                <div className="text-center mx-auto d-flex flex-column justify-content-center">
                    <img width="96" src={curso} alt=""
                         className={`${colorClassImage.two} mx-auto`}/>
                    <div
                        className="stepper__circle col-3 col-md-12 text-center d-flex flex-column align-items-center my-3">
                        <span className={`${colorClassStep.two} rounded-circle py-2 px-3 text-white`}>2</span>
                    </div>
                    <p className="fw-bold mb-0">En curso</p>
                    {
                        dateStepTwo ? <small>{dateStepTwo}</small> : <small>&nbsp;</small>
                    }
                </div>
            </div>
            <div className="col d-flex align-items-center flex-md-column-reverse my-3">
                <div className="text-center mx-auto d-flex flex-column justify-content-center">
                    <img width="96" src={entregado} alt=""
                         className={`${colorClassImage.three} mx-auto`}/>
                    <div
                        className="stepper__circle col-3 col-md-12 text-center d-flex flex-column align-items-center my-3">
                        <span
                            className={`${colorClassStep.three} rounded-circle py-2 px-3 text-white`}>3</span>
                    </div>
                    <p className="fw-bold mb-0">Entregado</p>
                    {
                        dateStepThree ? <small>{dateStepThree}</small> : <small>&nbsp;</small>
                    }
                </div>
            </div>
        </div>
    )
};

export default Step;
