import React from 'react';

const OrderInfo = ({id = '', nameContactFrom= '', nameContactTo=''} = {}) => (
    <div className="mt-4 px-4">
        <h2 className="text-center text-dark-blue fs-5">Seguimiento del pedido</h2>
        <div className="pb-2 mt-4">
            <div className="row fs-7 lh-1 mb-2">
                <div className="col-5 col-md-6 d-flex justify-content-end"><span className="text-primary fw-bold">ID:</span></div>
                <div className="col"><span>{ id }</span></div>
            </div>
            <div className="row fs-7 lh-1 mb-2">
                <div className="col-5 col-md-6 d-flex justify-content-end"><span className="text-primary fw-bold">REMITENTE:</span></div>
                <div className="col"><span>{ nameContactFrom.toUpperCase() }</span></div>
            </div>
            <div className="row fs-7 lh-1">
                <div className="col-5 col-md-6 d-flex justify-content-end"><span className="text-primary fw-bold">DESTINATARIO:</span></div>
                <div className="col"><span>{ nameContactTo.toUpperCase() }</span></div>
            </div>
        </div>
    </div>
);

export default OrderInfo;
