import TrackingLayout from "./layouts/Tracking";
import TrackingPage from "./pages/Tracking";
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
      <Router history={ true }>
          <TrackingLayout>
            <TrackingPage/>
          </TrackingLayout>
      </Router>
  );
}

export default App;
