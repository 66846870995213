import React from 'react';
import linkedin from '../../../assets/images/icons/linkedin.svg'
import instagram from '../../../assets/images/icons/instagram.svg'
import facebook from '../../../assets/images/icons/facebook.svg'
import twitter from '../../../assets/images/icons/twitter.svg'
import logoWepay from '../../../assets/images/logo-wepay4u.svg'

const Footer = () => {
    const phoneNumber = '971772101';
    const urlFacebook = 'https://www.facebook.com/Wepay4u-104920678410633';
    const urlInstagram = 'https://www.instagram.com/weplus4u/';
    const urlLinkedin = 'https://www.linkedin.com/company/74050324/admin/';
    const urlTwitter = 'https://twitter.com/wepay4u_oficial';
    return (
        <footer className="container py-4 border-top w-100 mt-5 d-flex justify-content-center justify-content-md-between flex-wrap">
            <div className="d-flex">
                <div className="pe-2 border-end">
                    <a href={ urlLinkedin } rel='noreferrer' target="_blank">
                        <img width="20" className="me-2" src={linkedin} alt="icono linkedin"/>
                    </a>
                    <a href={ urlInstagram } rel='noreferrer' target="_blank">
                        <img width="20" className="me-2" src={instagram} alt="icono instagram"/>
                    </a>
                    <a href={ urlFacebook } rel='noreferrer' target="_blank">
                        <img width="20" className="me-2" src={facebook} alt="icono facebook"/>
                    </a>
                    <a href={ urlTwitter } rel='noreferrer' target="_blank">
                        <img width="20" className="me-2" src={twitter} alt="icono twitter"/>
                    </a>
                </div>
            </div>
            <div className="mt-4 mt-md-0">
                <img src={logoWepay} alt="logo wepay4u"/>
            </div>
        </footer>
    )
}

export default Footer;
