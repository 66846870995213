import React, {useEffect, useState} from 'react';
import OrderInfo from "../../components/OrderInfo";
import Step from "../../components/Step";
import StatusMessage from "../../components/StatusMessage";
import Addresses from "../../components/Addresses";
import API from "../../api"
import {useLocation} from "react-router-dom";


const TrackingPage = () => {
    const [order, setOrder] = useState(null);
    const search = useLocation().search;

    const getOrder = async () => {
        const token = new URLSearchParams(search).get('token');
        let API_KEY
        if (token) {
            API_KEY = token
        } else {
            API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')
        }
        try {
            const fetchOrder = await API.get(`/order/tracking?hash=${API_KEY}`);
            setOrder(fetchOrder);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => getOrder(), []);

    useEffect(() => {
        const interval = setInterval(() => getOrder(), 10000);
        return () => clearInterval(interval)
    }, [getOrder]);

    const getAddress = (address) => {
        if (address) {
            let apt = ''
            let floor = ''
            if (address.apartment) {
                apt = `Apt.: ${address.apartment},`
            } else if (address.floor) {
                floor = `piso: ${address.floor}`
            }
            return `${address.street} # ${address.number}, ${apt} ${floor} ${address.cityName} - ${address.stateName}`
        }
    }


    return (
        <>
            <OrderInfo id={order?.orderId} nameContactFrom={order?.contactFrom?.name}
                       nameContactTo={`${order?.contactTo?.firstName} ${order?.contactTo?.lastName}`}/>
            <hr/>
            <Step history={order?.statusHistory}/>
            <StatusMessage history={order?.statusHistory}/>
            <br/>
            <hr/>
            <Addresses origin={getAddress(order?.from)} destination={getAddress(order?.to)}/>
        </>
    );
}

export default TrackingPage;
