import React from 'react';
import './style.scss'

const Addresses = ({origin, destination}) => (
    <div className="Addresses w-100 mt-5">
        <div className="feature bg-primary mx-auto rounded-2 py-4 px-5">
            <div className="text-white mb-2 d-flex flex-wrap justify-content-center justify-content-md-start">
                <span className="fw-bold me-2">Lugar de origen:</span>
                <span className="text-center text-md-start">{origin}</span>
            </div>
            <div className="text-white d-flex d-flex flex-wrap justify-content-center justify-content-md-start">
                <span className="fw-bold me-2">Lugar destino: </span>
                <span className="text-center text-md-start"> {destination}</span>
            </div>
        </div>
    </div>
);

export default Addresses;
