import React from 'react';
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import './style.scss'

const TrackingLayout = ({children}) => (
    <>
        {/*<Header/>*/}
        <div className="container">
            {children}
        </div>
        <Footer/>
    </>
);

export default TrackingLayout;
