import _axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
console.log(BASE_URL)

const axios = _axios.create({
    baseURL: BASE_URL,
    timeout: 10000
});

axios.interceptors.response.use((response) => {
    return response.data !== undefined ? response.data : null;
});

export default {
    get (url, params, { isAbsoluteURL = false } = {}, config) {
        const options = isAbsoluteURL ? { baseURL: BASE_URL } : {};
        if (typeof params !== 'undefined') {
            options.params = params;
        }
        if (config !== 'undefined') options.headers = config
        return axios.get(url, options);
    },
    post (url, data, { isAbsoluteURL = false, contentType = 'application/json', params = null } = {}) {
        const options = isAbsoluteURL ? { baseURL: BASE_URL } : {};
        options.headers = {
            'Content-Type': contentType
        };
        if (params) {
            options.params = params;
        }
        return axios.post(url, data, options);
    },
    put (url, data, { isAbsoluteURL = false, contentType = 'application/json' } = {}) {
        const options = isAbsoluteURL ? { baseURL: BASE_URL } : {};
        options.headers = {
            'Content-Type': contentType
        };
        return axios.put(url, data, options);
    },
    delete (url, { isAbsoluteURL = false, params = {} } = {}) {
        const options = isAbsoluteURL ? { baseURL: BASE_URL } : {};
        options.params = params;
        return axios.delete(url, options);
    },
};
