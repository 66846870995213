import React, {useEffect, useState} from 'react';
import {STATUS_TRANSACTIONS} from "../../utils/const";
import './style.scss'

const StatusMessage = ({ history = []} = {}) => {
    const errorsEntered = [STATUS_TRANSACTIONS.ERROR_VALIDATION, STATUS_TRANSACTIONS.CANCELED, STATUS_TRANSACTIONS.ERROR]
    const okEntered = [STATUS_TRANSACTIONS.CREATED, STATUS_TRANSACTIONS.CONFIRMED, STATUS_TRANSACTIONS.READY]
    const [state, setState] = useState('');
    const [message, setMessage] = useState('');
    const getStatus = () => {
        const delivered = history.findIndex(state => state.status === STATUS_TRANSACTIONS.DELIVERED)
        const inTransit = history.findIndex(state => state.status === STATUS_TRANSACTIONS.IN_TRANSIT)
        const entered = history.findIndex(state => okEntered.includes(state.status))
        const returned = history.findIndex(state => state.status === STATUS_TRANSACTIONS.RETURNED)
        const errorEntered = history.findIndex(state => errorsEntered.includes(state.status))
        const error = history.findIndex(state => state.status === STATUS_TRANSACTIONS.ERROR)

        if (returned !== -1){
            setState('danger')
            setMessage('<p class="text-center fs-5 fw-bold m-0 text-danger">Tu pedido ha sido devuelto</p>')
        } else if (delivered !== -1){
            setState('success')
            setMessage('<p class="text-center fs-5 fw-bold m-0 text-success">¡Hemos finalizado tu entrga!</p>')
        } else if (inTransit !== -1 && error === -1) {
            setState('success')
            setMessage('<p class="text-center fs-5 fw-bold m-0 text-success">Nuestro repartidor está en camino a entregar tu paquete</p>')
        } else if (errorEntered !== -1) {
            setState('danger')
            setMessage('<p class="text-center fs-5 fw-bold m-0 text-danger">Algo salió mal, tu pedido ha sido cancelado.</p> <p class="text-center m-0 text-danger">Ponte en contacto con tu comercio</p>')
        } else if (entered !== -1) {
            setState('success')
            setMessage('<p class="text-center fs-5 fw-bold m-0 text-success">¡Hemos recibido tu pedido con éxito!</p>')
        }
    }
    useEffect(() => getStatus())
    return (
        <div className={`StatusMessage w-100 mx-auto p-3 mt-4 border-${state}`}>
            <div dangerouslySetInnerHTML={{__html: message}} />
        </div>
    );
}

export default StatusMessage;
