export const STATUS_TRANSACTIONS = {
    CREATED: 'CREATED',
    READY: 'READY',
    CONFIRMED: 'CONFIRMED',
    IN_TRANSIT: 'IN_TRANSIT',
    ERROR: 'ERROR', // 1 validar con la historia
    CANCELED: 'CANCELED', // step 1
    RETURNED: 'RETURNED', // step 3
    DELIVERED: 'DELIVERED',
    ERROR_VALIDATION: 'ERROR_VALIDATION', // step 1
}
